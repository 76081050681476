import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#335370",
            main: "#00294D",
            dark: "#001c35",
            contrastText: "#ffffff",
        },
        secondary: {
            light: "#bdac67",
            main: "#ad9841",
            dark: "#796a2d",
            contrastText: "#fff",
        },
    },
});

export default theme;
