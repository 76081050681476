import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Toolbar from "@material-ui/core/Toolbar";
import { Navigation } from ".";
import config from "../../utils/siteConfig";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Twitter from "@material-ui/icons/Twitter";
import Instagram from "@material-ui/icons/Instagram";
import Facebook from "@material-ui/icons/Facebook";
import Themes from "../../styles/theme";
// Styles
import "../../styles/app.css";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {"Copyright © "}
            terminos y condiciones
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `5px solid ${theme.palette.divider}`,
        height: "75px",
        color: "white",
    },
    toolbarTitle: {
        flex: 1,
    },
    logo: {
        width: 90,
    },
    toolbarSecondary: {
        justifyContent: "space-between",
        overflowX: "auto",
        color: "white",
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    footer: {
        padding: theme.spacing(4, 4),
        marginTop: "auto",
        justifyContent: "space-between",
        overflowX: "auto",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[500]
                : theme.palette.grey[800],
    },
}));

const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const classes = useStyles();
    const site = data.allGhostSettings.edges[0].node;
    const twitterUrl = site.twitter
        ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = site.facebook
        ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
        : null;
    const themes = Themes;

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <ThemeProvider theme={themes}>
                <div className="viewport">
                    <div className="viewport-top">
                        {/* The main header section on top of the screen */}
                        <Toolbar
                            className={classes.toolbar}
                            style={{
                                ...(site.cover_image && {
                                    backgroundImage: `url(${site.cover_image})`,
                                }),
                            }}
                        >
                            <div className="site-mast-left">
                                <Link to="/">
                                    {site.logo ? (
                                        <img
                                            className={classes.logo}
                                            src={site.logo}
                                            alt={site.title}
                                        />
                                    ) : (
                                        <Img
                                            fixed={
                                                data.file.childImageSharp.fixed
                                            }
                                            alt={site.title}
                                        />
                                    )}
                                </Link>
                            </div>
                            <Typography
                                component="h2"
                                variant="h5"
                                color="inherit"
                                align="center"
                                noWrap
                                className={classes.toolbarTitle}
                            >
                                {site.title}
                            </Typography>
                        </Toolbar>
                        <Toolbar
                            component="nav"
                            variant="dense"
                            className={classes.toolbarSecondary}
                            style={{
                                ...(site.cover_image && {
                                    backgroundImage: `url(${site.cover_image})`,
                                }),
                            }}
                        >
                            <Navigation
                                data={site.navigation}
                                className={classes.toolbarLink}
                            />
                        </Toolbar>

                        <main className="site-main">
                            {/* All the main content gets inserted here, index.js, post.js */}
                            {children}
                        </main>
                    </div>

                    <div className="viewport-bottom">
                        {/* The footer at the very bottom of the screen */}
                        <footer className={classes.footer}>
                            <Toolbar>
                                <Container maxWidth="xl">
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                    >
                                        Creado por Alejandro Miguel
                                    </Typography>
                                </Container>
                                <Container maxWidth="sm">
                                    <Typography variant="body1">
                                        Todos los derechos estan reservados
                                    </Typography>
                                    <Copyright />
                                </Container>
                                <Container maxWidth="sm">
                                    <Typography
                                        component="h3"
                                        variant="h5"
                                        color="white"
                                        noWrap
                                    >
                                        Redes Sociales
                                    </Typography>
                                    <IconButton href="https://twitter.com/BufeteMiguel">
                                        <Twitter />
                                    </IconButton>
                                    <IconButton href="https://www.instagram.com/bjma_law/">
                                        <Instagram />
                                    </IconButton>
                                    <IconButton href="https://www.facebook.com/BufeteMiguelAsociados/">
                                        <Facebook />
                                    </IconButton>

                                    <Container maxWidth="sm" />
                                </Container>
                            </Toolbar>
                        </footer>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
